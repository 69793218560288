
<div>
    <button class="button-1" data-bs-target="#cart-bar-right" data-bs-toggle="offcanvas" style="cursor:pointer;">
        <img alt="" class="cart-icon me-2" src="/public-assets/cart-icon">
        <span class="d-none d-md-block me-1">Cart</span>
        (<template v-if="cartLoading">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only"></span>
            </div>
        </template>
        <template v-else>{{ cartSize }}</template>)
    </button>
    <CartBar/>
</div>
