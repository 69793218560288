
<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="true" tabindex="-1" id="cart-bar-right"
     aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header sidebar-header-cart ">
        <div class="d-flex justify-content-between align-items-center w-100">
            <div class="products-count ">{{ products_count }} item{{ products_count > 1 ? 's' : '' }} |
                ${{ total.toFixed(2) }}
            </div>
            <button data-bs-dismiss="offcanvas" aria-label="Close" @click.prevent="closeOffCanvas"
                    class="bg-transparent border-0 shadow-none bord">
                <img alt="close" src="/public-assets/close" class="close-sidebarCart">
            </button>
        </div>
    </div>
    <div class="offcanvas-body pt-0">
        <div v-if="!cartLoading && products && products.length > 0">
            <ProductBar v-for="(product, index) in products"
                        :key="'product_' + product.id"
                        :customId="index + 1"
                        :product="product"
                        :hasPendingCart="hasPendingCart"
                        :discountData="discountData"
                        @removedProduct="removeItemFromList"
                        @orderUpdated="orderUpdated"/>
            <!--@updatedProduct="updatedProduct"-->
        </div>
        <div v-else-if="!cartLoading">
            <div class="p-3">
                You don't have any courses in your cart
            </div>
        </div>
        <div v-else>
            <div class="skeleton-container">
                <div class="skeleton-item"></div>
            </div>
            <div class="skeleton-container">
                <div class="skeleton-item"></div>
            </div>
        </div>
    </div>
    <div class="p-3 position-sticky fixed-bottom offcanvas-footer">
        <div v-if="!cartLoading" class="shopping-cart flex-wrap">
            <OrderInfo ref="orderInfo"
                       :order="cartProductList"
                       :intent="intent"
                       :discountCodeFromIntent="discountCodeFromIntent"
                       :discountFromInvite="discountFromInvite"
                       :showDiscountProp="true"
                       @updateTotal="updateTotal"
                       @orderUpdated="orderUpdated"/>
        </div>
        <div class="d-flex flex-column pt-1">
            <button :disabled="!(products_count > 0)" class="btn btn-warning"
                    @click.prevent="redirectTo('/checkout')">
                Continue
            </button>
        </div>
    </div>
</div>
