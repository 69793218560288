
<div>
    <div class="accordion product-accordion" role="tablist">
        <div class="card">
            <div class="card-header">
                <div class="sku-container-bar my-3">
                    <div class="d-flex flex-row ">
                        <div class="d-flex flex-column">
                            <div class="sku-name-product-bar">
                                {{ product.name }}
                            </div>
                            <div class="sku">
                                {{ product.sku.sku }}
                            </div>
                            <div class="sku-regular-price mt-1">
                                ${{ verifyCurrentPrice().toFixed(2) }}
                                <span v-if="(product.sku.volume_prices)">
                                    <span v-if="(product.quantity >= 5)" class="old-price">${{
                                            product.sku.price.toFixed(2)
                                        }}</span>
                                </span>
                            </div>
                            <div class="d-sm-block" v-if="!hasVolumeDiscount">
                                <template v-if="product.sku.volume_prices && product.sku.volume_prices.length > 0">
                                    <div v-for="(volume_price, index) in product.sku.volume_prices" :key="index">
                                        <span class="price-stats-bar">
                                            Purchase {{ volume_price.quantity }}+ seats for ${{
                                                volume_price.price.toFixed(2)
                                            }} each</span>
                                    </div>
                                </template>
                            </div>
                            <div class="d-sm-block" v-else>
                                <template>
                                    <div v-for="(volume_price, index) in ranges" :key="index">
                                        <span class="price-stats-bar">
                                            Purchase {{ volume_price.quantity }}+ seats for ${{
                                                volume_price.price.toFixed(2)
                                            }} each</span>
                                    </div>
                                </template>
                            </div>
                            <div class="d-flex flex-row align-items-center mt-2">
                                <div v-if="!status.delete" class="d-flex align-items-center">
                                    <div class="d-flex fa-trash-can-xmark btn trash p-1 align-items-center"
                                         v-if="!hasPendingCart"
                                         style="font-size: 14px;"
                                         @click.prevent="remove">
                                        <i class="fa fa-trash me-1" @click.prevent="remove"></i>
                                        <span>Delete</span>
                                    </div>
                                </div>
                                <div v-else id="loading" class="spinner-border text-secondary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <Number v-model="product.quantity"
                                            :disabled="status.delete || hasPendingCart || (discountData && discountData.sku_id === product.sku_id)"
                                            :field="{slug: 'quantity', placeholder: 'quantity'}"
                                            class="form-control-bar"/>
                                </div>
                                <span class="d-flex align-items-center justify-content-center p-0 my-0 ms-3">${{
                                        (Math.abs(product.quantity) * verifyCurrentPrice()).toFixed(2)
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
