<template>
    <div>
        <button class="button-1" data-bs-target="#cart-bar-right" data-bs-toggle="offcanvas" style="cursor:pointer;">
            <img alt="" class="cart-icon me-2" src="/public-assets/cart-icon">
            <span class="d-none d-md-block me-1">Cart</span>
            (<template v-if="cartLoading">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                </div>
            </template>
            <template v-else>{{ cartSize }}</template>)
        </button>
        <CartBar/>
    </div>
</template>

<script>
import cart from '/components/pub/mixins/cart.js';
import CartBar from './CartBar.vue';

export default {
    mixins: [cart],
    components: {
        CartBar
    },
    mounted() {
        this.updateInfo();
    },
    methods: {
        removeProduct(product) {
            let index = this.products.findIndex(x => x.id === product.id);
            if (this.index !== -1) {
                this.shoppingCartNumberDown();

                const key = this.order.relationships.orderItemsGroups ? 'orderItemsGroups' : 'order_items_groups'
                this.order.relationships[key].splice(index, 1);
            }
        },
        shoppingCartNumberDown() {
            this.cartSize -= 1;
        }
    },
}
</script>