import Vue from 'vue'
import App from '@/components/pub/cartButton.vue'
import axios from 'axios'
import VeeValidate, {Validator} from 'vee-validate';


Validator.extend('phone', {
    getMessage: field => 'The ' + field + ' field is required',
    validate: value => value.toString().replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', '').replaceAll(' ', '').length >= 10
});


axios.defaults.headers.common['x-api-key'] = '9c6334a7-8215-46ff-8ec9-f442cdc43cac';
Vue.prototype.$axios = axios;

Vue.use(VeeValidate, {inject: false});

new Vue({
  render: h => h(App),
}).$mount('#cartButton')