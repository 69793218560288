<template>
    <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="true" tabindex="-1" id="cart-bar-right"
         aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header sidebar-header-cart ">
            <div class="d-flex justify-content-between align-items-center w-100">
                <div class="products-count ">{{ products_count }} item{{ products_count > 1 ? 's' : '' }} |
                    ${{ total.toFixed(2) }}
                </div>
                <button data-bs-dismiss="offcanvas" aria-label="Close" @click.prevent="closeOffCanvas"
                        class="bg-transparent border-0 shadow-none bord">
                    <img alt="close" src="/public-assets/close" class="close-sidebarCart">
                </button>
            </div>
        </div>
        <div class="offcanvas-body pt-0">
            <div v-if="!cartLoading && products && products.length > 0">
                <ProductBar v-for="(product, index) in products"
                            :key="'product_' + product.id"
                            :customId="index + 1"
                            :product="product"
                            :hasPendingCart="hasPendingCart"
                            :discountData="discountData"
                            @removedProduct="removeItemFromList"
                            @orderUpdated="orderUpdated"/>
                <!--@updatedProduct="updatedProduct"-->
            </div>
            <div v-else-if="!cartLoading">
                <div class="p-3">
                    You don't have any courses in your cart
                </div>
            </div>
            <div v-else>
                <div class="skeleton-container">
                    <div class="skeleton-item"></div>
                </div>
                <div class="skeleton-container">
                    <div class="skeleton-item"></div>
                </div>
            </div>
        </div>
        <div class="p-3 position-sticky fixed-bottom offcanvas-footer">
            <div v-if="!cartLoading" class="shopping-cart flex-wrap">
                <OrderInfo ref="orderInfo"
                           :order="cartProductList"
                           :intent="intent"
                           :discountCodeFromIntent="discountCodeFromIntent"
                           :discountFromInvite="discountFromInvite"
                           :showDiscountProp="true"
                           @updateTotal="updateTotal"
                           @orderUpdated="orderUpdated"/>
            </div>
            <div class="d-flex flex-column pt-1">
                <button :disabled="!(products_count > 0)" class="btn btn-warning"
                        @click.prevent="redirectTo('/checkout')">
                    Continue
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import OrderInfo from '../../Pages/Checkout/components/OrderInfo.vue'
import ProductBar from '../../Pages/Checkout/components/ProductBar.vue'
import pricesConfig from '../../Pages/Checkout/components/prices-config'
import cart from "@/components/pub/mixins/cart";

export default {
    mixins: [pricesConfig, cart],
    components: {OrderInfo, ProductBar},
    data() {
        return {
            total: 0,
            discountFromInvite: null
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        let params = {'owned': false, 'discount': urlParams.get('discount')}
        if (!urlParams.get('discount')) {
            this.$axios.get('/api/cart', {params}).then(response => {
                this.setCartProductList(response.data.data)
                this.storage = response.data.storage || 'default';
                this.cartIntent = response.data.intent || {};
            }).finally(() => {
                this.loadingFinished = true
            })
        } else {
            this.$axios.post('/api/cart-invite', {params}).then(response => {
                this.storage = response.data.storage || 'default';
                if (response.data.error) {
                    this.$swal('Error..', response.data.error, 'error')
                      .then((value) => {
                          if (value) {
                              window.location.href = '/';
                          }
                      });
                } else {
                    this.setCartProductList(response.data.data)
                    this.cartIntent = response.data.intent || {};
                    this.discountFromInvite = response.data.discount
                }
            }).finally(() => {
                this.loadingFinished = true
            })
        }
    },
    computed: {
        hasPendingCart() {
            // return Object.keys(this.intent || {}).length > 0
            return this.intent.status === 'processing' || this.intent.status === 'finished'
        },
        discountCodeFromIntent() {
            return this.intent.data?.discount_data?.code
        },
        discountData() {
            return this.intent.data?.discount_data
        },
    },
    methods: {
        orderUpdated({intent}) {
            if (intent) this.setIntent(intent)
        },
        updateTotal(newValue) {
            this.total = newValue
        },
        redirectTo(url) {
            window.location.href = url
        },
        closeOffCanvas() {
            let offcanvas_el = document.getElementById("cart-bar-right")
            let offcanvas = new bootstrap.Offcanvas(offcanvas_el)
            offcanvas.hide()
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-warning:disabled {
    background-color: #474646 !important;
    border-color: #474646 !important;
    color: #fff !important;
    cursor: not-allowed;
}

.products-count {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.sidebar-header-cart {
    background-color: #474646 !important;
    color: white !important;
}

.skeleton-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px 10px;
}

.skeleton-item {
    min-width: 50px;
    flex: 1;
    height: 180px;
    animation: pulse 5s infinite;
}

@keyframes pulse {
    0% {
        background-color: rgb(192, 192, 192);
    }
    50% {
        background-color: #e0e0e0
    }
    75% {
        background-color: #ffffff
    }
}
</style>