<template>
    <div>
        <div class="accordion product-accordion" role="tablist">
            <div class="card">
                <div class="card-header">
                    <div class="sku-container-bar my-3">
                        <div class="d-flex flex-row ">
                            <div class="d-flex flex-column">
                                <div class="sku-name-product-bar">
                                    {{ product.name }}
                                </div>
                                <div class="sku">
                                    {{ product.sku.sku }}
                                </div>
                                <div class="sku-regular-price mt-1">
                                    ${{ verifyCurrentPrice().toFixed(2) }}
                                    <span v-if="(product.sku.volume_prices)">
                                        <span v-if="(product.quantity >= 5)" class="old-price">${{
                                                product.sku.price.toFixed(2)
                                            }}</span>
                                    </span>
                                </div>
                                <div class="d-sm-block" v-if="!hasVolumeDiscount">
                                    <template v-if="product.sku.volume_prices && product.sku.volume_prices.length > 0">
                                        <div v-for="(volume_price, index) in product.sku.volume_prices" :key="index">
                                            <span class="price-stats-bar">
                                                Purchase {{ volume_price.quantity }}+ seats for ${{
                                                    volume_price.price.toFixed(2)
                                                }} each</span>
                                        </div>
                                    </template>
                                </div>
                                <div class="d-sm-block" v-else>
                                    <template>
                                        <div v-for="(volume_price, index) in ranges" :key="index">
                                            <span class="price-stats-bar">
                                                Purchase {{ volume_price.quantity }}+ seats for ${{
                                                    volume_price.price.toFixed(2)
                                                }} each</span>
                                        </div>
                                    </template>
                                </div>
                                <div class="d-flex flex-row align-items-center mt-2">
                                    <div v-if="!status.delete" class="d-flex align-items-center">
                                        <div class="d-flex fa-trash-can-xmark btn trash p-1 align-items-center"
                                             v-if="!hasPendingCart"
                                             style="font-size: 14px;"
                                             @click.prevent="remove">
                                            <i class="fa fa-trash me-1" @click.prevent="remove"></i>
                                            <span>Delete</span>
                                        </div>
                                    </div>
                                    <div v-else id="loading" class="spinner-border text-secondary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <Number v-model="product.quantity"
                                                :disabled="status.delete || hasPendingCart || (discountData && discountData.sku_id === product.sku_id)"
                                                :field="{slug: 'quantity', placeholder: 'quantity'}"
                                                class="form-control-bar"/>
                                    </div>
                                    <span class="d-flex align-items-center justify-content-center p-0 my-0 ms-3">${{
                                            (Math.abs(product.quantity) * verifyCurrentPrice()).toFixed(2)
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import product from './product';
import Number from '/components/pub/helpers/fields/Number.vue';

export default {
    props: {
        product: Object,
        customId: Number,
        hasPendingCart: Boolean,
        discountData: { type: Object, default: null },
    },
    mixins: [product],
    components: {Number}
}
</script>

<style lang="scss" scoped>
#black {
    color: #7F7F7F;
}

.sku-regular-price {
    color: white;
    font-weight: 500;
}

.price-stats-bar {
    font-size: 12px;
    font-weight: 400;
    color: #676767;
    white-space: nowrap;
}

.sku-img-container-bar {
    margin-top: 5px;
}

.fake-sku-image-bar {
    width: 60px;
    height: auto;
    background-color: gray;
    margin-right: 10px;
}

.sku-name-product-bar {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.trash {
    color: #C83232;
    font-size: 15px;
    text-decoration: underline;
    padding: 10px;
}

.trash:hover {
    color: red;
}

.text-muted-1 {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    padding: 8px;
    margin-left: 10px;
}

.form-control-bar {
    margin-left: 55px;
    margin-right: 5px;
    width: 10px;
    height: 22px;
    padding: 0;
    text-align: center;
}

.old-price {
    color: #A2A2A2;
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
    text-decoration: line-through;
}

#loading {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 42px;
    margin-top: 5px;
    padding: 10px;
}
</style>